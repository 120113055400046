import React, { useContext, useEffect, useState } from 'react'
import { Toolbar, Box, Paper, IconButton, InputBase, List, ListItem, Button, Dialog, DialogActions, DialogContent, Drawer, AppBar } from '@material-ui/core'
import { Search, MenuOutlined } from '@material-ui/icons'
import { navigate, StaticQuery, graphql } from 'gatsby'
import SEO from 'components/SEO'
import { makeStyles } from '@material-ui/core/styles'
import Context from 'reactContext'
import { UserType } from 'consts'
import { get } from 'utils/request'
import { logout } from 'http/self'

const Main = ({ children }) => {
  const { settingsMerge, view, selfSet, viewSetIn } = useContext(Context)
  const [visible, setVisible] = useState(false)
  const [open, setOpen] = useState(true)
  const drawerWidth = open ? 120 : 0
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'block',
      marginLeft: drawerWidth,
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: 10,
      paddingTop: 80,
    },
    paper: {
      padding: '2px 4px',
      display: 'flex',
      marginLeft: '10px',
      alignItems: 'center',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 0,
    },
  }))
  const classes = useStyles()
  const getInitialData = async () => {
    const res = await get('managers/initial_data')
    if (res.ok) {
      selfSet(res.self)
      settingsMerge(res.settings || {})
    }
    return res.body
  }

  useEffect(() => {
    if (localStorage.getItem('USER_TYPE') !== UserType.manager) {
      navigate('/')
    }
    getInitialData()
  }, [])
  const navData = () => {
    const navList = []
    return navList.concat([
      { name: 'overview', text: '账户总览' },
      { name: 'users', text: '活跃成员' },
      { name: 'payments', text: '支付记录' },
      { name: 'fees', text: '费用明细' },
      { name: 'downloads', text: '立即下载' },
      { name: 'logout', text: '退出登录' },
    ])
  }
  const searchBox = (
    <Paper className={classes.paper}>
      <InputBase value={view.get('searchText')} fullWidth className={classes.input} onChange={(e) => viewSetIn(['searchText'], e.target.value)} placeholder="搜索" />
      <IconButton className={classes.iconButton}>
        <Search />
      </IconButton>
    </Paper>
  )

  return (
    <StaticQuery
      query={graphql`
        query MainQuery {
          site {
            siteMetadata {
              title
              keywords
              description
              favicon
            }
          }
        }
      `}
      render={(data) => {
        const {
          site: {
            siteMetadata: { title, keywords, description, favicon },
          },
        } = data
        return (
          <Box bgcolor="background.paper" color="text.primary">
            <SEO title={title} keywords={keywords} description={description} favicon={favicon} />
            <div className={classes.root}>
              <AppBar className={classes.appBar} color="inherit">
                <Toolbar>
                  <IconButton color="inherit" onClick={() => setOpen(!open)}>
                    <MenuOutlined />
                  </IconButton>
                  {searchBox}
                </Toolbar>
              </AppBar>
              <div>
                <Drawer
                  open={open}
                  variant="persistent"
                  onClose={() => setOpen(false)}
                  anchor="left"
                  className={classes.drawer}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  <List className="h-screen p-3">
                    <div className="text-center">
                      <div role="presentation" className="back-logo-qjs cursor-pointer mt-1" onClick={() => navigate('/')} />
                    </div>
                    {navData().map((i) => (
                      <ListItem button key={i.name} className="text-sm text-center my-6" onClick={i.name === 'logout' ? () => setVisible(true) : () => navigate(`/main/${i.name}`)}>
                        {i.text}
                      </ListItem>
                    ))}
                  </List>
                </Drawer>
              </div>
              <div className={classes.content}>{children}</div>
              <Dialog open={visible} onClose={() => setVisible(false)}>
                <DialogContent>您确定要退出企业控制台吗？</DialogContent>
                <DialogActions>
                  <Button onClick={() => setVisible(false)} color="primary">
                    取消
                  </Button>
                  <Button
                    onClick={() => {
                      logout()
                      setVisible(false)
                    }}
                    color="primary"
                  >
                    确定
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Box>
        )
      }}
    />
  )
}

export default Main
